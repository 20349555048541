import { render, staticRenderFns } from "./serviceCenter.vue?vue&type=template&id=33d76623&scoped=true&"
import script from "./serviceCenter.vue?vue&type=script&lang=js&"
export * from "./serviceCenter.vue?vue&type=script&lang=js&"
import style0 from "./serviceCenter.vue?vue&type=style&index=0&id=33d76623&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d76623",
  null
  
)

export default component.exports