<template>
	<div class="page">
		<el-breadcrumb separator=">>">
			<el-breadcrumb-item :to="{ path: '/' }" replace>
				{{lang==6?'首页':'Home'}}
			</el-breadcrumb-item>
			<el-breadcrumb-item>
				{{lang==6?'客户互动服务中心':'Customer Interaction service Center'}}
			</el-breadcrumb-item>
		</el-breadcrumb>
		<div class="title">
			{{info.title}}
		</div>
		<div class="text" v-html="info.describe"></div>
		<swiper :options="swiperOption" ref="mySwiper">
			<swiper-slide class="swiper-slide" v-for="(item,index) in bannerList" :key="index">
				<img :src="item.image" mode="widthFit" class="banner" />
			</swiper-slide>
			<div class="swiper-pagination" slot="pagination"></div>
		</swiper>
		<video :src="info.video" v-if="info.video"></video>
	</div>
</template>

<script>
	import {
		swiper,
		swiperSlide
	} from "vue-awesome-swiper/src/index.js";
	import 'swiper/dist/css/swiper.css';
	import mixinLang from '../components/mixin.js'
	export default {
		mixins: [mixinLang],
		components: {
			swiper,
			swiperSlide
		},
		data() {
			return {
				bannerList: [],
				info: {},
				// 轮播配置
				swiperOption: {
					slidesPerView: 1,
					loop: true,
					autoplay: {
						delay: 3000,
						stopOnLastSlide: false,
						disableOnInteraction: false
					},
					// 显示分页
					pagination: {
						el: ".swiper-pagination",
						clickable: true //允许分页点击跳转
					}
				}
			}
		},
		methods: {
			getInfo() {
				this.$api.getConfigure({
					type: 15
				}).then(res => {
					console.log(res)
					this.info = res.data
				})
				this.$api.getBannerList({
					cate_no: '324gxfg12'
				}).then(res => {
					this.bannerList = res.banner_list
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		padding-top: 111px;
		position: relative;
	}

	.el-breadcrumb {
		height: 40px;
		line-height: 40px;
		border-bottom: #999999 1px solid;
		position: absolute;
		top: 0;
		width: 100%;
		padding: 0 111px;
		color: #333333 !important;
	}

	/deep/.el-breadcrumb__inner {
		color: #333333 !important;
	}

	/deep/.is-link {
		font-weight: normal;
		color: #333333 !important;
	}

	/deep/ .el-breadcrumb__separator {
		color: #333333 !important;
	}

	.text {
		padding: 50px 111px 111px;
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 18px;
		font-weight: 350;
		line-height: 36px;
		color: #333333;
	}

	.title {
		font-family: Montserrat-Bold, sans-serif;
		font-size: 32px;
		font-weight: bold;
		color: #97233F;
		padding: 0 111px;
	}

	img {
		width: 100%;
		vertical-align: middle;
	}

	/deep/ .swiper-pagination-bullet-active {
		background-color: #97233F;
	}

	video {
		height: 911px;
		width: 100%;
	}
</style>